<template>
  <div>
    <NavigationBar
      titel="Abrechnungsstapel"
      :actions="actions"
      isPreview
    ></NavigationBar>

    <section>
      <template>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <Subheader icon="mdi-invoice-list" title="Abrechnungsstapel">
              </Subheader>
              <div class="mt-7">
                <batches-data-table
                  :batches="batches"
                ></batches-data-table></div
            ></v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import BatchesDataTable from "@/components/accounting/batches/BatchesDataTable.vue";
import { ACCOUNTING } from "@/store/modules.js";
import { GET_BATCHES } from "@/store/action-types.js";

import { ACCOUNTING_PAYROLL_BATCHES_create } from "@/data/permission-types.js";
export default {
  name: "accounting-payroll",
  components: {
    NavigationBar,
    Subheader,
    BatchesDataTable,
    SupportTools,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          permission: `${ACCOUNTING_PAYROLL_BATCHES_create}`,
          icon: "mdi-plus-circle",
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({ name: "accounting-payroll-new" });
          },
        },
      ],
    };
  },
  computed: {
    batches() {
      return this.$store.state.accounting.batches;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "batches", data: this.batches }];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      return this.$store.dispatch(`${ACCOUNTING}${GET_BATCHES}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
  },
};
</script>
